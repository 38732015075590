import React, { FC, useEffect } from 'react'
import { RootState, useAppDispatch } from '../../../store'
import {
  sendPageLoaded,
  sendQuizFinished,
} from '../../../store/analytics/analytics'

import QuizCard from './QuizCard'
import StageNavigation from '../StageNavigation'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'

const QuizStage: FC = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  //const { pageId } = useParams()
  const { eventCode, codename } = useSelector((state: RootState) => state.misc)

  const {
    data: { pages },
  } = useSelector((state: RootState) => state.quiz)
  const {
    currentPage,
    data: { score },
  } = useSelector((state: RootState) => state.quiz)

  const page = pages[currentPage]

  useEffect(() => {
    navigate(`/${eventCode}/${codename}/${currentPage}`)
  }, [currentPage, eventCode, codename, navigate])

  //this only runs once
  useEffect(() => {
    dispatch(sendPageLoaded(page))
    if (page.is_last) {
      dispatch(sendQuizFinished(score))
    }
  }, [currentPage])

  return (
    <div
      className="quiz-page"
      style={{ marginBottom: 50, display: 'flex', flexDirection: 'column' }}
    >
      <div id="cards">
        {page.cards.map((card) => {
          // Return the element. Also pass key
          return <QuizCard card={card} key={card.id}></QuizCard>
        })}
        <StageNavigation
          backward={{
            text: 'Zurück',
            handler: () => {
              console.log('zurück')
            },
          }}
          forward={{
            text: 'Weiter',
            handler: () => {
              console.log('vorwärts')
            },
          }}
        />
      </div>
    </div>
  )
}

export default QuizStage
