import { Card, Col } from 'react-bootstrap'
import React, { FC } from 'react'
import styled, { css, keyframes } from 'styled-components'

import { IItem } from '../../../store/quiz/interfaces'
import ReactCardFlip from 'react-card-flip'
import memory_back from '../../../staticfiles/images/memory_back.png'
import { tada } from 'react-animations'

const tadaAnimation = keyframes`${tada}`

const Img = styled(Card.Img)`
  width: 100px;
  height: 100px;
`
const StyledCol = styled(Col)`
  flex-grow: 0 !important;
  margin-top: 15px;
  margin-bottom: 15px;
  animation: ${(props) =>
    props.tada === 'true'
      ? css`
          ${tadaAnimation} 1s
        `
      : ''};
`

interface MemoryCardCardProps {
  i: IItem
  handleClick: (i: IItem) => void
  codename: string
}

const MemoryCardCard: FC<MemoryCardCardProps> = ({
  i,
  handleClick,
  codename,
}) => {
  const BASE_URL = window._env_.REACT_APP_MEDIA_BASE_URL
  return (
    <StyledCol tada={i.correct.toString()}>
      <ReactCardFlip isFlipped={i.flipped} flipDirection="horizontal">
        <Card
          onClick={() => {
            handleClick(i)
          }}
        >
          <Img variant="top" src={memory_back} />
        </Card>
        <Card
          onClick={() => {
            handleClick(i)
          }}
        >
          <Img
            variant="top"
            src={`${BASE_URL}/uploads/images/${codename}/${i.img}`}
          />
        </Card>
      </ReactCardFlip>
    </StyledCol>
  )
}

export default MemoryCardCard
