import React, { FC } from 'react'
import { Form } from 'react-bootstrap'

interface CustomSelectProps {
  condition: boolean | string | undefined
  id: string
  choices: string[]
  value: string
  handler: (key: string, value: string) => void
  status: undefined | boolean
}

const CustomSelect: FC<CustomSelectProps> = ({
  status,
  condition,
  id,
  choices,
  value,
  handler,
}) => {
  return (
    <Form.Select
      size="lg"
      className={`w-auto d-inline-block ${
        condition === true || status === true
          ? 'alert-success'
          : condition === 'skip'
            ? 'alert-danger'
            : ''
      }`}
      disabled={condition === true || condition === 'skip' || status === true}
      onChange={(event) => {
        handler(id, event.target.value)
      }}
      value={value}
    >
      {choices.map((value: string, index: number) => {
        return <option key={index}>{value}</option>
      })}
    </Form.Select>
  )
}

export default CustomSelect
