import React, { FC } from 'react'
import { RootState, useAppDispatch } from '../../../store'
import {
  increaseAttemptCounter,
  setBooleanAnswer,
  setCardCondition,
  setGuide,
} from '../../../store/quiz/quiz'

import { CardProps } from '../../../store/quiz/interfaces'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ModalInstructionsBoolean from './ModalInstructionsBoolean'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

const BooleanCard: FC<CardProps> = ({ card, mediaUrl }) => {
  const { currentPage } = useSelector((state: RootState) => state.quiz)
  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  const checkAnswer = (answer: boolean) => {
    const correct = card.question_data.choices_boolean.correct_answer === answer
    dispatch(
      setCardCondition({
        page: currentPage,
        cardId: card.id,
        condition: correct,
      })
    )
    dispatch(
      increaseAttemptCounter({
        page: currentPage,
        cardId: card.id,
      })
    )
    dispatch(
      setGuide({
        page: currentPage,
        cardId: card.id,
        condition: correct,
      })
    )
  }

  return (
    <div id={card.region} className="col-md-12">
      <div
        className={`panel ${card.type} ${card.codename} ${
          card.condition === true
            ? 'panel-success'
            : card.condition === false
              ? 'panel-warning'
              : 'panel-default'
        }`}
      >
        {card.title ? (
          <div className="panel-heading">
            <h3 className="panel-title">{card.title}</h3>
          </div>
        ) : (
          <div></div>
        )}
        <div className="panel-body">
          <div className="card-content">
            {card.content ? (
              <div
                className="content"
                dangerouslySetInnerHTML={{
                  __html: card.content.replace('{{MEDIA_IMAGE}}', mediaUrl),
                }}
              ></div>
            ) : (
              <div></div>
            )}
            <div className="question-text">
              <ModalInstructionsBoolean></ModalInstructionsBoolean>
              <div
                dangerouslySetInnerHTML={{
                  __html: card.question_data.question_text.replace(
                    '{{MEDIA_IMAGE}}',
                    mediaUrl
                  ),
                }}
              ></div>
            </div>
          </div>
        </div>
        <div className="panel-footer">
          <button
            className="btn btn-primary answer-true"
            disabled={card.condition === true}
            onClick={() => {
              dispatch(
                setBooleanAnswer({
                  page: currentPage,
                  cardId: card.id,
                  answer: true,
                })
              )
              checkAnswer(true)
            }}
          >
            <FontAwesomeIcon icon={'check'} />{' '}
            {card.question_data.choices_boolean.label_true}
          </button>
          <button
            className="btn btn-default answer-false"
            disabled={card.condition === true}
            onClick={() => {
              dispatch(
                setBooleanAnswer({
                  page: currentPage,
                  cardId: card.id,
                  answer: false,
                })
              )
              checkAnswer(false)
            }}
          >
            <FontAwesomeIcon icon={'times'} />{' '}
            {card.question_data.choices_boolean.label_false}
          </button>
          <small className="pull-right text-right">
            {card.condition === true ? (
              <div>
                <FontAwesomeIcon icon={'check'} /> {t('question_status.right')}
              </div>
            ) : card.condition === false ? (
              <div>
                <FontAwesomeIcon icon={'undo'} /> {t('question_status.wrong')}
              </div>
            ) : (
              <div>
                <FontAwesomeIcon icon={'minus'} />{' '}
                {t('question_status.unedited')}
              </div>
            )}
            {t('question_status.attempts')}: {card.number_of_attempts}
          </small>
          <span className="clearfix"></span>
        </div>
      </div>
    </div>
  )
}

export default BooleanCard
