import React, { FC } from 'react'
import { useDrag } from 'react-dnd'

interface DragItemProps {
  condition: boolean | string | undefined
  id: string
  value: string
  insideDrop: boolean
  status: boolean | undefined
  mediaUrl: string
}

const DragItem: FC<DragItemProps> = ({
  condition,
  id,
  value,
  insideDrop,
  status,
  mediaUrl = '',
}) => {
  const [, drag] = useDrag(() => ({
    // "type" is required. It is used by the "accept" specification of drop targets.
    type: 'BOX',
    item: { key: id, text: value },
    // The collect function utilizes a "monitor" instance (see the Overview for what this is)
    // to pull important pieces of state from the DnD system.
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }))

  return (
    <div
      role="Handle"
      ref={drag}
      id={id}
      className={`${insideDrop ? '' : 'col-sm-3 col-xs-4'} ${
        condition === true || condition === 'skip' || status === true
          ? 'disabled'
          : ''
      } btn btn-xs choice ui-draggable ui-draggable-handle`}
      style={{
        display: insideDrop ? '' : 'table',
        background:
          condition === 'skip'
            ? '#FF0000'
            : condition === true || status === true
              ? '#5cb85c'
              : 'lightgrey',
        color:
          (condition !== undefined && condition !== false) || status === true
            ? 'white'
            : '',
        margin: '10px',
      }}
    >
      <span
        style={{
          display: insideDrop ? '' : 'table-cell',
          verticalAlign: 'middle',
        }}
        dangerouslySetInnerHTML={{
          __html: value.replace('{{MEDIA_IMAGE}}', mediaUrl),
        }}
      ></span>
    </div>
  )
}

export default DragItem
