import React, { FC } from 'react'
import { RootState } from '../../store'
import { Row } from 'react-bootstrap'
import { isTabletOrMobileQuery } from '../../utils'
import { useMediaQuery } from 'react-responsive'
import { useSelector } from 'react-redux'

const MainNavbar: FC = () => {
  const { data: quiz } = useSelector((state: RootState) => state.quiz)
  const { client_title, logo } = useSelector((state: RootState) => state.theme)

  const isTabletOrMobile = useMediaQuery(isTabletOrMobileQuery)

  if (isTabletOrMobile) {
    return (
      <>
        <Row
          className="navbar-default"
          style={{
            margin: 0,
            marginBottom: 25,
          }}
        >
          <div className="container-fluid">
            <div
              className="navbar-header"
              style={{
                marginLeft: 10,
                marginRight: 10,
                padding: 0,
              }}
            >
              <img className="navbar-logo" src={logo} alt="Logo"></img>
            </div>

            <div className="navbar-text" style={{ margin: 0, paddingTop: 12 }}>
              <span style={{ fontSize: '1rem' }}>
                {client_title}:<br></br>
              </span>
              {quiz.title}
            </div>
          </div>
        </Row>
      </>
    )
  } else {
    return (
      <>
        <Row
          className="navbar-default"
          style={{
            margin: 0,
            marginBottom: 25,
          }}
        >
          <div className="container-fluid">
            <div
              className="navbar-header"
              style={{
                marginLeft: 100,
                marginRight: 50,
                padding: 0,
              }}
            >
              <img className="navbar-logo" src={logo} alt="Logo"></img>
            </div>
            <div className="navbar-text" style={{ margin: 0, padding: 0 }}>
              <span style={{ fontSize: '1rem' }}>
                {client_title}:<br></br>
              </span>
              {quiz.title}
            </div>
          </div>
        </Row>
      </>
    )
  }
}

export default MainNavbar
