import React, { FC } from 'react'
import { RootState, useAppDispatch } from '../../../store'
import {
  increaseAttemptCounter,
  setCardCondition,
  setGuide,
  setPassword,
  setPasswordSentence,
} from '../../../store/quiz/quiz'

import { CardProps } from '../../../store/quiz/interfaces'
import CustomPasswordCheck from './CustomPasswordCheck'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ModalInstructionsPassword from './ModalInstructionsPassword'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

const PasswordCard: FC<CardProps> = ({ card }) => {
  const { currentPage } = useSelector((state: RootState) => state.quiz)
  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  const handlePassswordChange = (value: string) => {
    if (card.condition !== true) {
      dispatch(
        setPassword({
          page: currentPage,
          cardId: card.id,
          data: value,
        })
      )
    }
  }
  const handleSentenceChange = (value: string) => {
    if (card.condition !== true) {
      dispatch(
        setPasswordSentence({
          page: currentPage,
          cardId: card.id,
          data: value,
        })
      )
    }
  }

  const checkAnswer = () => {
    const numbers = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9']
    const punctuation = ['.', '!', '?']
    const password = card.question_data.password.password.split('').toString()
    const sentence_splitted = card.question_data.password.sentence.split('')
    let sentence = card.question_data.password.sentence
      .split(' ')
      .map((e) => {
        if (numbers.includes(e.split('')[0])) {
          return e.split('').map((a) => {
            return a
          })
        } else {
          return e[0]
        }
      })
      .toString()
    if (
      !numbers.includes(sentence_splitted[sentence_splitted.length - 2]) &&
      punctuation.includes(sentence_splitted[sentence_splitted.length - 1])
    ) {
      sentence =
        sentence + ',' + sentence_splitted[sentence_splitted.length - 1]
    }
    const correct = password === sentence
    dispatch(
      setCardCondition({
        page: currentPage,
        cardId: card.id,
        condition: correct,
      })
    )
    dispatch(
      increaseAttemptCounter({
        page: currentPage,
        cardId: card.id,
      })
    )
    dispatch(
      setGuide({
        page: currentPage,
        cardId: card.id,
        condition: correct,
      })
    )
  }

  return (
    <div id={card.region} className="col-md-12">
      <div
        className={`panel ${card.type} ${card.codename} ${
          card.condition === true
            ? 'panel-success'
            : card.condition === false
              ? 'panel-warning'
              : 'panel-default'
        }`}
      >
        {card.title ? (
          <div className="panel-heading">
            <h3 className="panel-title">{card.title}</h3>
          </div>
        ) : (
          <div></div>
        )}
        <div className="panel-body">
          <div className="card-content">
            {card.content ? (
              <div
                className="content"
                dangerouslySetInnerHTML={{
                  __html: card.content,
                }}
              ></div>
            ) : (
              <div></div>
            )}
            <div className="question-text">
              <ModalInstructionsPassword></ModalInstructionsPassword>
              <div
                dangerouslySetInnerHTML={{
                  __html: card.question_data.question_text,
                }}
              ></div>
            </div>
          </div>
          <form className="form">
            <div className="form-group">
              <label>{t('password_card.sentence_text')}:</label>
              <CustomPasswordCheck
                condition={card.condition}
                value={card.question_data.password.sentence}
                handler={handleSentenceChange}
              ></CustomPasswordCheck>
            </div>
            <div className="form-group">
              <label>{t('password_card.password_text')}:</label>
              <CustomPasswordCheck
                condition={card.condition}
                value={card.question_data.password.password}
                handler={handlePassswordChange}
              ></CustomPasswordCheck>
            </div>
          </form>
        </div>
        <div className="panel-footer">
          <button
            className="btn btn-primary btn-attempt"
            onClick={() => {
              checkAnswer()
            }}
            disabled={card.condition === true}
            hidden={card.condition === true}
          >
            <FontAwesomeIcon icon={'check'} /> {t('text_card.answer')}
          </button>
          <small className="pull-right text-right">
            {card.condition === true ? (
              <div>
                <FontAwesomeIcon icon={'check'} /> {t('question_status.right')}
              </div>
            ) : card.condition === false ? (
              <div>
                <FontAwesomeIcon icon={'undo'} /> {t('question_status.wrong')}
              </div>
            ) : (
              <div>
                <FontAwesomeIcon icon={'minus'} />{' '}
                {t('question_status.unedited')}
              </div>
            )}
            {t('question_status.attempts')}: {card.number_of_attempts}
          </small>
        </div>
      </div>
    </div>
  )
}

export default PasswordCard
