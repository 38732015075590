import { BrowserRouter, Route, Routes } from 'react-router-dom'
import React, { FC } from 'react'
import { Container } from 'react-bootstrap'
import Footer from './components/shared/Footer'
import InitialData from './components/shared/InitialData'
import MainNavbar from './components/shared/MainNavbar'
import { Provider } from 'react-redux'
import QuizGuide from './components/shared/QuizGuide'
import QuizStage from './components/shared/quiz/QuizStage'
import StageIndicator from './components/shared/StageIndicator'
import StartPage from './components/shared/start/StartPage'
import { isTabletOrSmallDesktopQuery } from './utils'
import store from './store'
import { useMediaQuery } from 'react-responsive'

const QuizApp: FC = () => {
  return (
    <Provider store={store}>
      <BrowserRouter basename={'/'}>
        <InitialData>
          <MainNavbar />
          <StageIndicator />
          <div
            className="sticky-top"
            style={{
              width: useMediaQuery(isTabletOrSmallDesktopQuery) ? '' : '1300px',
            }}
          >
            <QuizGuide />
          </div>
          <Container>
            <Routes>
              <Route path={'/start?'} element={<StartPage />} />
              <Route path={'/:eventCode/:codename/*'} element={<QuizStage />} />
            </Routes>
          </Container>
          <Footer />
        </InitialData>
      </BrowserRouter>
    </Provider>
  )
}

export default QuizApp
