import {
  ThunkAction,
  UnknownAction,
  combineReducers,
  configureStore,
} from '@reduxjs/toolkit'
import { load, save } from 'redux-localstorage-simple'
import analytics from './analytics/analytics'
import misc from './misc/misc'
import quiz from './quiz/quiz'
import theme from './theme/theme'
import { useDispatch } from 'react-redux'

const combinedReducer = combineReducers({
  analytics,
  quiz,
  misc,
  theme,
})

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const rootReducer = (state: any, action: UnknownAction) => {
  if (action.type === 'reset') {
    state = undefined
  }
  return combinedReducer(state, action)
}

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(save()),
  preloadedState: load(),
})

export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  UnknownAction
>

export type AppDispatch = typeof store.dispatch
export const useAppDispatch: () => AppDispatch = useDispatch

export default store
