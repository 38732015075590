import React, { FC } from 'react'
import { RootState } from '../../store'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

const Footer: FC = () => {
  const { theme_key } = useSelector((state: RootState) => state.theme)
  const { t } = useTranslation()
  if (theme_key !== 'cornelsen') {
    return (
      <footer
        className="margin-bottom text-muted text-small pt-3 bottom"
        style={{
          background: '#777777',
          padding: 10,
          paddingLeft: '5%',
          position: 'absolute',
          bottom: '0px',
          width: '100%',
        }}
      >
        <a
          href="https://mecodia.de"
          target="_blank"
          style={{ color: 'white' }}
          rel="noreferrer"
        >
          © mecodia
        </a>
        <br />
        <a
          href="http://mecodia.de/imprint.html"
          target="_blank"
          style={{ color: '#E3E3E3' }}
          rel="noreferrer"
        >
          {t('footer.imprint')}
        </a>{' '}
        <a
          href="https://mf.mecodia.de/privacy"
          target="_blank"
          style={{ color: '#E3E3E3' }}
          rel="noreferrer"
        >
          | {t('footer.privacy_policy')}
        </a>
      </footer>
    )
  } else {
    return (
      <footer
        className="margin-bottom text-muted text-small pt-3 bottom"
        style={{
          background: '#f8f9fa',
          paddingLeft: '10%',
          position: 'absolute',
          bottom: '0',
          width: '100%',
          height: '50px',
        }}
      >
        <a
          href="https://www.cornelsen.de/impressum"
          target="_blank"
          style={{ color: '#DE2922' }}
          rel="noreferrer"
        >
          {t('footer.imprint')}
        </a>{' '}
      </footer>
    )
  }
}

export default Footer
