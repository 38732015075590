import React, { FC, useEffect } from 'react'
import { RootState, useAppDispatch } from '../../store'
import { fetchQuiz, fetchSchools } from '../../store/quiz/quiz'

import StartPage from './start/StartPage'
import { createAction } from '@reduxjs/toolkit'
import { fetchTheme } from '../../store/theme/theme'
import { matchPath } from 'react-router-dom'
import { useSelector } from 'react-redux'

const InitialData: FC<{ children?: JSX.Element[] }> = (props) => {
  const { baseUrl, eventCode, codename } = useSelector(
    (state: RootState) => state.misc
  )
  const dispatch = useAppDispatch()
  const { children } = props

  const { done } = useSelector((state: RootState) => state.quiz)
  const { theme_key } = useSelector((state: RootState) => state.theme)
  const KSKTheme = React.lazy(() => import('../themes/KSKTheme'))
  const VobaTheme = React.lazy(() => import('../themes/VobaTheme'))
  const CornelsenTheme = React.lazy(() => import('../themes/CornelsenTheme'))

  if (window.location.pathname === '' || window.location.pathname === '/') {
    const resetAction = createAction('reset')
    dispatch(resetAction())
    window.location.replace('/start')
  }

  const match = matchPath(
    {
      path: '/:event/:code/0',
      caseSensitive: true,
      end: true,
    },
    window.location.pathname
  )

  useEffect(() => {
    if (codename && eventCode) {
      dispatch(fetchQuiz())
      dispatch(fetchTheme())
      dispatch(fetchSchools())
    }
  }, [dispatch, baseUrl, eventCode, codename, theme_key])

  if (done) {
    return (
      <>
        <React.Suspense fallback={<></>}>
          {theme_key === 'voba' && <VobaTheme />}
          {theme_key === 'new_ksk' && <KSKTheme />}
          {theme_key === 'cornelsen' && <CornelsenTheme />}
        </React.Suspense>
        {children}
      </>
    )
  } else {
    if (match) {
      return (
        <StartPage
          initialEventCode={match.params.event}
          initialCodename={match.params.code}
        />
      )
    }
    return <StartPage />
  }
}

export default InitialData
