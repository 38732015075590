import React, { FC, useState } from 'react'
import { RootState, useAppDispatch } from '../../../store'
import { downloadCertificate, imageToDataURL } from './utils'

import { CardProps } from '../../../store/quiz/interfaces'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { sendCertificateLoaded } from '../../../store/analytics/analytics'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

const CertificateCard: FC<CardProps> = ({ card }) => {
  const dispatch = useAppDispatch()
  const { score, title, certificate_text_bottom, certificate_text_top } =
    useSelector((state: RootState) => state.quiz.data)

  const { theme_key, logo } = useSelector((state: RootState) => state.theme)
  const { t } = useTranslation()
  const [name, setName] = useState('')
  let backgroundImg: string | undefined = undefined
  let logoImg: string | undefined = undefined

  imageToDataURL(
    `${window._env_.REACT_APP_CERTIFICATE_IMAGE_PATH}/${theme_key}.jpg`,
    'image/png'
  ).then((url) => (backgroundImg = url))

  imageToDataURL(`${logo}`, 'image/png', true).then((url) => (logoImg = url))

  return (
    <div id={card.region} className="col-md-12">
      <div className={`panel panel-default ${card.type} ${card.codename}`}>
        {card.title ? (
          <div className="panel-heading">
            <h3 className="panel-title">{card.title}</h3>
          </div>
        ) : (
          <div></div>
        )}
        <div className="panel-body">
          <p className="well">
            {card.grade_text} <strong>{score}</strong>
          </p>
          <h3>{t('certificate_card.title')}</h3>
          <div dangerouslySetInnerHTML={{ __html: card.info_text }}></div>
          <div className="row">
            <div className="col-md-6">
              <label htmlFor={`input-name-${card.id}`}>
                {t('certificate_card.name_input')}:
              </label>
              <br></br>
              <input
                type="text"
                className="form-control"
                id={`input-name-${card.id}`}
                value={name}
                onChange={(e) => {
                  setName(e.currentTarget.value)
                }}
              ></input>
            </div>
          </div>
        </div>
        <div className="panel-footer">
          <button
            className="btn btn-primary btn-certificate"
            onClick={() => {
              downloadCertificate(
                backgroundImg,
                logoImg,
                name,
                title,
                card.content,
                card.quiz_type,
                certificate_text_top,
                certificate_text_bottom,
                score
              )
              dispatch(sendCertificateLoaded())
            }}
          >
            <FontAwesomeIcon icon={'download'} />{' '}
            {t('certificate_card.pdf_download')}
          </button>
        </div>
      </div>
    </div>
  )
}

export default CertificateCard
