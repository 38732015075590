import { Button, Modal } from 'react-bootstrap'
import React, { useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'

function ModalInstructionsMemory(): JSX.Element {
  const [show, setShow] = useState(false)
  const { t } = useTranslation()

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  const BASE_URL = window._env_.REACT_APP_MEDIA_BASE_URL
  return (
    <>
      <Button
        variant=""
        onClick={handleShow}
        className="btn btn-default btn-show-modal-help pull-right"
      >
        <FontAwesomeIcon icon={'question'} /> {t('instructions.title')}
      </Button>

      <Modal show={show} onHide={handleClose} animation={false} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>{t('instructions.title')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p></p>
          <div className="row">
            <div className="col-md-6">
              <p>{t('instructions.memory_card')}</p>
            </div>
            <div className="col-md-6">
              <img
                src={`${BASE_URL}/uploads/images/hilfe/memory.png`}
                className="img-thumbnail"
                alt="Bildpaare-Aufgabe"
              ></img>
            </div>
          </div>
          <p></p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            type="button"
            variant=""
            className="btn-default"
            onClick={handleClose}
          >
            <FontAwesomeIcon icon={'times'} /> {t('instructions.close')}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default ModalInstructionsMemory
