import React, { FC } from 'react'
import DragItem from './DragItem'
import { IDroppedItem } from '../../../store/quiz/interfaces'
import { useDrop } from 'react-dnd'

interface DropItemProps {
  condition: boolean | string | undefined
  id: string
  text: string
  droppedItem: IDroppedItem
  handleDropped: (item: IDroppedItem, choiceId: string) => void
  mediaUrl: string
}

const DropItem: FC<DropItemProps> = ({
  condition,
  id,
  text,
  droppedItem,
  handleDropped,
  mediaUrl,
}) => {
  const [{ isOver }, drop] = useDrop(() => ({
    // The type (or types) to accept - strings or symbols
    accept: 'BOX',
    // Props to collect
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
    drop: (item: IDroppedItem) => handleDropped(item, id),
  }))

  return (
    <div className="row choice-row">
      <div
        className="col-sm-3 col-xs-4 placeholder ui-droppable"
        ref={drop}
        role={'Dustbin'}
        style={{ backgroundColor: isOver ? 'darkgrey' : 'lightgrey' }}
      >
        {droppedItem.key && droppedItem.text && (
          <DragItem
            key={droppedItem.key}
            status={droppedItem.status}
            condition={condition}
            id={droppedItem.key}
            value={droppedItem.text}
            insideDrop={true}
            mediaUrl={mediaUrl}
          ></DragItem>
        )}
      </div>
      <div className="col-sm-9 col-xs-7 choices-right" id={id}>
        {text}
      </div>
    </div>
  )
}

export default DropItem
