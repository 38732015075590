import React, { FC } from 'react'
import { RootState, useAppDispatch } from '../../../store'
import {
  increaseAttemptCounter,
  setCardCondition,
  setGuide,
  setSequenceData,
  setSequenceStatus,
  skipSequence,
} from '../../../store/quiz/quiz'

import { CardProps } from '../../../store/quiz/interfaces'
import CustomSequence from './CustomSequence'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ModalInstructionsSequencing from './ModalInstructionsSequencing'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

const SequencingCard: FC<CardProps> = ({ card }) => {
  const { currentPage } = useSelector((state: RootState) => state.quiz)
  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  const handleDataChange = (key: number, value: string[]) => {
    if (card.condition !== true) {
      dispatch(
        setSequenceData({
          page: currentPage,
          cardId: card.id,
          key: key,
          data: value,
        })
      )
    }
  }

  const skip = () => {
    dispatch(
      setCardCondition({
        page: currentPage,
        cardId: card.id,
        condition: 'skip',
      })
    )
    dispatch(
      setGuide({
        page: currentPage,
        cardId: card.id,
        condition: 'skip',
      })
    )
    dispatch(
      skipSequence({
        page: currentPage,
        cardId: card.id,
      })
    )
  }

  const compareArrays = function (a1: string[], a2: string[]) {
    let i = a1.length
    if (i !== a2.length) return false
    while (i--) {
      if (a1[i] !== a2[i]) return false
    }
    return true
  }

  const checkAnswer = () => {
    const answers = card.question_data.choices_sequencing.map((e) => {
      const is_sequence_correct = compareArrays(e.data, e.choices)
      dispatch(
        setSequenceStatus({
          page: currentPage,
          cardId: card.id,
          status: is_sequence_correct,
          key: e.key,
        })
      )
      return is_sequence_correct
    })
    const correct = !answers.includes(false)
    dispatch(
      setCardCondition({
        page: currentPage,
        cardId: card.id,
        condition: correct,
      })
    )
    dispatch(
      increaseAttemptCounter({
        page: currentPage,
        cardId: card.id,
      })
    )
    dispatch(
      setGuide({
        page: currentPage,
        cardId: card.id,
        condition: correct,
      })
    )
  }

  return (
    <div id={card.region} className="col-md-12">
      <div
        className={`panel ${card.type} ${card.codename} ${
          card.condition === true
            ? 'panel-success'
            : card.condition === 'skip'
              ? 'panel-danger'
              : card.condition === false
                ? 'panel-warning'
                : 'panel-default'
        }`}
      >
        {card.title ? (
          <div className="panel-heading">
            <h3 className="panel-title">{card.title}</h3>
          </div>
        ) : (
          <div></div>
        )}
        <div className="panel-body">
          <div className="card-content">
            {card.content ? (
              <div
                className="content"
                dangerouslySetInnerHTML={{
                  __html: card.content,
                }}
              ></div>
            ) : (
              <div></div>
            )}
            <div className="question-text">
              <ModalInstructionsSequencing></ModalInstructionsSequencing>
              <div
                dangerouslySetInnerHTML={{
                  __html: card.question_data.question_text,
                }}
              ></div>
            </div>
          </div>
          <div className="form form-inline form-inline-no-flex solution">
            {card.question_data.choices_sequencing.map((e, i) => {
              if (e.type === 'text') {
                return (
                  <CustomSequence
                    status={e.status}
                    condition={card.condition}
                    key={e.key}
                    choices={e.data}
                    values={e.data}
                    index={i}
                    handler={handleDataChange}
                  ></CustomSequence>
                )
              } else {
                return <></>
              }
            })}
          </div>
        </div>
        <div className="panel-footer">
          <button
            className="btn btn-primary btn-attempt"
            onClick={() => {
              checkAnswer()
            }}
            disabled={card.condition === true || card.condition === 'skip'}
            hidden={card.condition === true || card.condition === 'skip'}
          >
            <FontAwesomeIcon icon={'check'} /> {t('question_status.check')}
          </button>
          <button
            className="btn btn-default btn-skip"
            onClick={skip}
            disabled={card.condition === true || card.condition === 'skip'}
            hidden={card.condition === true || card.condition === 'skip'}
          >
            <FontAwesomeIcon icon={'share'} /> {t('question_status.skip')}
          </button>
          <small className="pull-right text-right">
            {card.condition === true ? (
              <div>
                <FontAwesomeIcon icon={'check'} /> {t('question_status.right')}
              </div>
            ) : card.condition === false ? (
              <div>
                <FontAwesomeIcon icon={'undo'} /> {t('question_status.wrong')}
              </div>
            ) : card.condition === 'skip' ? (
              <div>
                <FontAwesomeIcon icon={'undo'} /> {t('question_status.skipped')}
              </div>
            ) : (
              <div>
                <FontAwesomeIcon icon={'minus'} />{' '}
                {t('question_status.unedited')}
              </div>
            )}
            {t('question_status.attempts')}: {card.number_of_attempts}
          </small>
          <span className="clearfix"></span>
        </div>
      </div>
    </div>
  )
}
export default SequencingCard
