import React, { FC } from 'react'
import { Form } from 'react-bootstrap'

interface CustomCharInputProps {
  condition: boolean | string | undefined
  answerkey: string
  value: string
  handler: (key: string, value: string) => void
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  focus: (e: { target: any }) => void
  status: boolean | undefined
}

const CustomCharInput: FC<CustomCharInputProps> = ({
  condition,
  answerkey,
  value,
  handler,
  focus,
  status,
}) => {
  return (
    <Form.Control
      as="input"
      className={`${
        condition === true || status === true
          ? 'alert-success'
          : condition === 'skip'
            ? 'alert-danger'
            : ''
      }`}
      disabled={condition === true || condition === 'skip' || status === true}
      value={value}
      style={
        condition === true
          ? { color: '#5CB85C', textAlign: 'center', padding: '0', margin: '0' }
          : { color: '', textAlign: 'center', padding: '0', margin: '0' }
      }
      onChange={(event) => {
        handler(answerkey, event.target.value.toUpperCase())
      }}
      onKeyUp={(event) => {
        focus(event)
      }}
      maxLength={1}
    ></Form.Control>
  )
}

export default CustomCharInput
