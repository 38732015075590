import React, { CSSProperties, FC } from 'react'

import { CardProps } from '../../../store/quiz/interfaces'

const ChapterCard: FC<CardProps> = ({ card, mediaUrl }) => {
  const backgroundStyle: CSSProperties = {
    position: 'relative',
    paddingBottom: '100%',
    height: 0,
    overflow: 'hidden',
  }
  const imageStyle: CSSProperties = {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
  }
  return (
    <div id={card.region} className="col-md-12">
      <div className={`${card.type} ${card.codename}`}>
        <div className="background" style={backgroundStyle}>
          <img
            src={card.background_img}
            alt="Hintergrund"
            style={imageStyle}
          ></img>
        </div>
        <div className="overlay well">
          <h3
            className="title"
            dangerouslySetInnerHTML={{ __html: card.title }}
          ></h3>
          <div
            dangerouslySetInnerHTML={{
              __html: card.content.replace('{{MEDIA_IMAGE}}', mediaUrl),
            }}
          ></div>
        </div>
      </div>
    </div>
  )
}

export default ChapterCard
