import Backend from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'
import translationDE from '../src/assets/locales/de/translation.json'
import translationEN from '../src/assets/locales/en/translation.json'
import translationES from '../src/assets/locales/es/translation.json'
import translationFR from '../src/assets/locales/fr/translation.json'

const fallbackLng = ['de']
const availableLanguages = [
  'de',
  'de-DE',
  'en',
  'en-GB',
  'es',
  'es-ES',
  'fr',
  'fr-FR',
]

export const resources = {
  de: {
    translation: translationDE,
  },
  en: {
    translation: translationEN,
  },

  es: {
    translation: translationES,
  },
  fr: {
    translation: translationFR,
  },
} as const

i18next
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng,

    debug: false,

    supportedLngs: availableLanguages,

    interpolation: {
      escapeValue: false,
    },
  })

export default i18next
