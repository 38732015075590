import React, { FC } from 'react'
import { Form } from 'react-bootstrap'

interface CustomInputProps {
  listed: boolean
  condition: boolean | string | undefined
  validText: string
  value: string
  handler: (key: string, value: string) => void
  status: undefined | boolean
}

const CustomInput: FC<CustomInputProps> = ({
  listed,
  condition,
  validText,
  value,
  handler,
  status,
}) => {
  return (
    <Form.Control
      as="input"
      className={`${
        (listed === true || value === '') &&
        condition !== true &&
        condition !== 'skip'
          ? ''
          : condition === true || status === true
            ? 'alert-success'
            : condition === 'skip'
              ? 'alert-danger'
              : 'alert-danger'
      }`}
      disabled={condition === true || condition === 'skip' || status === true}
      value={value}
      style={
        condition === true || status === true
          ? { color: '#5CB85C' }
          : { color: '' }
      }
      onChange={(event) => {
        handler(validText, event.target.value)
      }}
    ></Form.Control>
  )
}

export default CustomInput
