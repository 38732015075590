import { Nav, Navbar } from 'react-bootstrap'
import React, { Dispatch, FC, useRef } from 'react'
import { RootState, useAppDispatch } from '../../store'
import { UnknownAction, createAction } from '@reduxjs/toolkit'
import {
  clearGuide,
  nextPage,
  prevPage,
  setPageCondition,
} from '../../store/quiz/quiz'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { isTabletOrMobileQuery } from '../../utils'
import { useMediaQuery } from 'react-responsive'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

export type NavigationButton = {
  text: string
  handler: () => void | Promise<void>
  icon?: IconProp
  verifyClass?: string
}

export type StageNavigationProps = {
  forward?: NavigationButton
  backward?: NavigationButton
  hasRegistration?: boolean
}

type LocalStageNavigationProps = {
  scrollRef: React.RefObject<HTMLDivElement>
} & StageNavigationProps

const StageNavigation: FC<StageNavigationProps> = (props) => {
  const ref = useRef<HTMLDivElement>(null)
  return <DesktopStageNavigation {...props} scrollRef={ref} />
}

const resetAll = (dispatch: Dispatch<UnknownAction>) => {
  const resetAction = createAction('reset')
  dispatch(resetAction())
  window.location.replace('/')
}

export default StageNavigation

const DesktopStageNavigation = (props: LocalStageNavigationProps) => {
  const { forward, backward } = props
  const dispatch = useAppDispatch()
  const { currentPage, data } = useSelector((state: RootState) => state.quiz)
  const { t } = useTranslation()
  return (
    <div id="quiz-navigation" style={{ WebkitBackfaceVisibility: 'hidden' }}>
      <Navbar style={{ padding: '0' }}>
        <Nav.Item className="col-4" style={{ padding: '0' }}>
          {backward && (
            <Nav.Link
              hidden={data.pages.indexOf(data.pages[currentPage]) === 0}
              disabled={data.pages.indexOf(data.pages[currentPage]) === 0}
              className="btn btn-default previous"
              onClick={() => {
                dispatch(prevPage())
                dispatch(clearGuide())
                dispatch(
                  setPageCondition({
                    pagenumber: currentPage,
                  })
                )
                backward.handler()
              }}
            >
              <FontAwesomeIcon icon={backward.icon || 'arrow-circle-left'} />{' '}
              {t('stage_navigation.back')}
            </Nav.Link>
          )}
        </Nav.Item>

        <Nav.Item className="col-4">
          <Nav.Link
            className="btn btn-default"
            onClick={() => {
              resetAll(dispatch)
            }}
          >
            <FontAwesomeIcon icon={'undo'} />
            {useMediaQuery(isTabletOrMobileQuery) ? (
              <></>
            ) : (
              <> {t('stage_navigation.restart')}</>
            )}
          </Nav.Link>
        </Nav.Item>

        <Nav.Item className="col-4" style={{ padding: '0' }}>
          {forward && (
            <Nav.Link
              hidden={data.pages[currentPage].is_last}
              disabled={data.pages[currentPage].is_last}
              className="btn btn-primary next pull-right"
              onClick={() => {
                dispatch(nextPage())
                dispatch(clearGuide())
                dispatch(
                  setPageCondition({
                    pagenumber: currentPage,
                  })
                )
                forward.handler()
              }}
            >
              {t('stage_navigation.next')}{' '}
              <FontAwesomeIcon icon={forward.icon || 'arrow-circle-right'} />
            </Nav.Link>
          )}
        </Nav.Item>
      </Navbar>
    </div>
  )
}
