// small breakpoint for small smartphones
export const isSmallMobileQuery = { query: '(max-width: 320px)' }

// breakpoint for smartphones only
export const isMobileQuery = { query: '(max-width: 575px)' }

// medium breakpoint for small tablets or smartphones
export const isTabletOrMobileQuery = { query: '(max-width: 834px)' }

// large breakpoint for bigger tablets and desktops
export const isTabletOrSmallDesktopQuery = { query: '(max-width: 1200px)' }

export const isTouchDevice = (): boolean => {
  if ('ontouchstart' in window) {
    return true
  }
  return false
}
