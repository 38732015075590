import { Button, Form, Row, Spinner } from 'react-bootstrap'
import React, { FC, useEffect, useState } from 'react'
import { RootState, useAppDispatch } from '../../../store'
import Footer from '../Footer'
import { ISchool } from '../../../store/quiz/interfaces'
import { setEvent } from '../../../store/misc/misc'
import { setSchool as setSchoolData } from '../../../store/quiz/quiz'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

interface StartpageProps {
  initialEventCode?: string
  initialCodename?: string
}

const StartPage: FC<StartpageProps> = ({
  initialEventCode,
  initialCodename,
}) => {
  const { eventCode, codename } = useSelector((state: RootState) => state.misc)
  const [text, setText] = useState(
    eventCode && codename ? `${eventCode}.${codename}` : ''
  )
  const [school, setSchool] = useState({} as ISchool)
  const [classs, setClass] = useState('')
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { error, loading, currentPage, school_choices, loaded, done } =
    useSelector((state: RootState) => state.quiz)

  useEffect(() => {
    if (eventCode && codename && loaded && done) {
      navigate(`/${eventCode}/${codename}/${currentPage}`)
    }
  }, [eventCode, codename, loaded, done])

  useEffect(() => {
    if (initialEventCode && initialCodename) {
      setText(`${initialEventCode}.${initialCodename}`)
      dispatch(
        setEvent({
          eventCode: initialEventCode,
          codename: initialCodename,
        })
      )
    }
  }, [])

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const eventCode = text.split('.')[0]
    const codename = text.split('.')[1]
    if (eventCode && codename) {
      dispatch(
        setEvent({
          eventCode,
          codename,
        })
      )
    }
    if (school.name || classs !== '') {
      dispatch(setSchoolData({ school: school.name, class: classs }))
    }
  }

  const groupedSchoolChoices =
    loaded && school_choices && school_choices.length > 0
      ? (Object.groupBy(school_choices, ({ type }) => type) as Record<
          string,
          ISchool[]
        >)
      : undefined

  return (
    <>
      <div className="container mt-4">
        <div>
          <h1 style={{ color: '#10b4f1' }}>{t('start_page.title')}</h1>
          <p>{t('start_page.introduction')}</p>
          <p>{t('start_page.description')}</p>
          <Form onSubmit={handleSubmit}>
            <h4 style={{ color: '#10b4f1' }}>{t('start_page.code_input')}:</h4>
            <Form.Group>
              <Form.Control
                type="text"
                minLength={2}
                style={{
                  padding: '1rem',
                  marginBottom: '1%',
                  width: '100%',
                  outline: 'none',
                }}
                value={text}
                className="textinput"
                placeholder="Code"
                disabled={loading || loaded}
                onChange={(e) => {
                  setText(e.target.value)
                }}
                isInvalid={error && !(loading || loaded)}
              />
              <Form.Control.Feedback type="invalid">
                {t('start_page.invalid_code')}
              </Form.Control.Feedback>
            </Form.Group>
            {groupedSchoolChoices ? (
              <div>
                <Row
                  style={{
                    padding: '1rem',
                    marginBottom: '1%',
                    width: '100%',
                  }}
                >
                  <h4 style={{ color: '#10b4f1' }}>
                    {t('start_page.school_choice')}:
                  </h4>
                  <Form.Select
                    onChange={(e) => {
                      setSchool(
                        school_choices.find(
                          (s) => s.name === e.currentTarget.value
                        ) ||
                          ({
                            name: e.currentTarget.value,
                            type: '',
                            classes: [],
                          } as ISchool)
                      )
                    }}
                    disabled={classs !== ''}
                    defaultValue=""
                    required
                  >
                    <option hidden disabled value="">
                      --- {t('start_page.school_choice')} ---
                    </option>

                    {Object.entries(groupedSchoolChoices).map(
                      ([type, schools]) => (
                        <optgroup key={type} label={type}>
                          {schools.map((s: ISchool) => (
                            <option value={s.name} key={s.name}>
                              {s.name}
                            </option>
                          ))}
                        </optgroup>
                      )
                    )}

                    <option value={'Schule nicht in der Liste'}>
                      {t('start_page.school_not_listed')}
                    </option>
                  </Form.Select>
                </Row>
                {school && school.classes && school.classes.length > 0 ? (
                  <>
                    <Row
                      style={{
                        padding: '1rem',
                        marginBottom: '1%',
                        width: '100%',
                      }}
                    >
                      <h4 style={{ color: '#10b4f1' }}>
                        {t('start_page.class_choice')}:
                      </h4>
                      <Form.Select
                        onChange={(e) => setClass(e.currentTarget.value)}
                        defaultValue=""
                        required
                      >
                        <option hidden disabled value="">
                          --- {t('start_page.class_choice')} ---
                        </option>
                        {school.classes.map((c) => (
                          <option value={c} key={c}>
                            {c}
                          </option>
                        ))}
                        <option value={'Klasse nicht in der Liste'}>
                          {t('start_page.class_not_listed')}
                        </option>
                      </Form.Select>
                    </Row>
                  </>
                ) : (
                  <></>
                )}
              </div>
            ) : (
              <></>
            )}
            <Row>
              <Button
                type="submit"
                id="validateCodeButton"
                style={{
                  color: 'white',
                  background: '#10b4f1',
                  border: '#10b4f1',
                  width: '25',
                }}
                disabled={loading}
              >
                {loading ? (
                  <>
                    {t('start_page.loading')}
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  </>
                ) : (
                  t('start_page.start')
                )}
              </Button>
            </Row>
          </Form>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default StartPage
