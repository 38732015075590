import { CardProps, IItem } from '../../../store/quiz/interfaces'
import React, { FC, useState } from 'react'
import { RootState, useAppDispatch } from '../../../store'
import {
  increaseAttemptCounter,
  setCardCondition,
  setGuide,
  setMemoryItem,
  skipMemory,
} from '../../../store/quiz/quiz'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import MemoryCardCard from './MemoryCardCard'
import ModalInstructionsMemory from './ModalInstructionsMemory'
import { Row } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

const MemoryCard: FC<CardProps> = ({ card }) => {
  const {
    currentPage,
    data: { pages },
  } = useSelector((state: RootState) => state.quiz)
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const [lastFlipped, setLastFlipped] = useState({} as IItem)
  const page = pages[currentPage]

  const checkAnswer = () => {
    const correct =
      card.question_data.items
        .map((e) => {
          return e.correct
        })
        .filter((e) => e === false).length === 2
    dispatch(
      setCardCondition({
        page: currentPage,
        cardId: card.id,
        condition: correct,
      })
    )
    dispatch(
      setGuide({
        page: currentPage,
        cardId: card.id,
        condition: correct,
      })
    )
  }

  const skip = () => {
    dispatch(
      setCardCondition({
        page: currentPage,
        cardId: card.id,
        condition: 'skip',
      })
    )
    dispatch(
      setGuide({
        page: currentPage,
        cardId: card.id,
        condition: 'skip',
      })
    )
    if (card.condition !== true) {
      dispatch(
        skipMemory({
          page: currentPage,
          cardId: card.id,
        })
      )
    }
  }

  const handleClick = (i: IItem) => {
    if (!i.flipped) {
      //if first card clicked we flip it
      if (!lastFlipped.id) {
        dispatch(
          setMemoryItem({
            page: currentPage,
            cardId: card.id,
            itemId: i.id,
            correct: false,
            flipped: true,
          })
        )
        setLastFlipped(i)
      } else {
        const correct = lastFlipped.pair === i.pair
        if (correct) {
          //increase Attempt Counter
          dispatch(
            increaseAttemptCounter({
              page: currentPage,
              cardId: card.id,
            })
          )
          dispatch(
            setMemoryItem({
              page: currentPage,
              cardId: card.id,
              itemId: i.id,
              correct: correct,
              flipped: true,
            })
          )
          dispatch(
            setMemoryItem({
              page: currentPage,
              cardId: card.id,
              itemId: lastFlipped.id,
              correct: correct,
              flipped: true,
            })
          )
          checkAnswer()
        } else {
          //increase Attempt Counter
          dispatch(
            increaseAttemptCounter({
              page: currentPage,
              cardId: card.id,
            })
          )
          //flip card for a short while
          dispatch(
            setMemoryItem({
              page: currentPage,
              cardId: card.id,
              itemId: i.id,
              correct: correct,
              flipped: true,
            })
          )
          //after 2 seconds we flip the cards back
          setTimeout(() => {
            dispatch(
              setMemoryItem({
                page: currentPage,
                cardId: card.id,
                itemId: i.id,
                correct: correct,
                flipped: false,
              })
            )
            dispatch(
              setMemoryItem({
                page: currentPage,
                cardId: card.id,
                itemId: lastFlipped.id,
                correct: correct,
                flipped: false,
              })
            )
          }, 1000)
        }
        setLastFlipped({} as IItem)
      }

      //if second card flipped (lastFlipped.id !== null) we check if correct, if yes we send both
    }
  }

  return (
    <div id={card.region} className="col-md-12">
      <div
        className={`panel ${card.type} ${card.codename} ${
          card.condition === true
            ? 'panel-success'
            : card.condition === 'skip'
              ? 'panel-danger'
              : card.condition === false
                ? 'panel-warning'
                : 'panel-default'
        }`}
      >
        {card.title ? (
          <div className="panel-heading">
            <h3 className="panel-title">{card.title}</h3>
          </div>
        ) : (
          <div></div>
        )}
        <div className="panel-body">
          <div className="card-content">
            {card.content ? (
              <div
                className="content"
                dangerouslySetInnerHTML={{
                  __html: card.content,
                }}
              ></div>
            ) : (
              <div></div>
            )}
            <div className="question-text">
              <ModalInstructionsMemory></ModalInstructionsMemory>
              <div
                dangerouslySetInnerHTML={{
                  __html: card.question_data.question_text,
                }}
              ></div>
            </div>
          </div>
          <Row xs={2} md={4} className="g-4">
            {card.question_data.items.map((i) => {
              return (
                <MemoryCardCard
                  key={i.id}
                  i={i}
                  handleClick={handleClick}
                  codename={page.codename}
                ></MemoryCardCard>
              )
            })}
          </Row>
        </div>
        <div className="panel-footer">
          <button
            className="btn btn-default btn-skip"
            onClick={skip}
            disabled={card.condition === true || card.condition === 'skip'}
            hidden={card.condition === true || card.condition === 'skip'}
          >
            <FontAwesomeIcon icon={'share'} /> {t('question_status.skip')}
          </button>
          <small className="pull-right text-right">
            {card.condition === true ? (
              <div>
                <FontAwesomeIcon icon={'check'} /> {t('question_status.right')}
              </div>
            ) : card.condition === false ? (
              <div>
                <FontAwesomeIcon icon={'undo'} /> {t('question_status.wrong')}
              </div>
            ) : card.condition === 'skip' ? (
              <div>
                <FontAwesomeIcon icon={'undo'} /> {t('question_status.skipped')}
              </div>
            ) : (
              <div>
                <FontAwesomeIcon icon={'minus'} />{' '}
                {t('question_status.unedited')}
              </div>
            )}
            {t('question_status.attempts')}: {card.number_of_attempts}
          </small>
          <span className="clearfix"></span>
        </div>
      </div>
    </div>
  )
}

export default MemoryCard
