import React, { FC } from 'react'
import { CardProps } from '../../../store/quiz/interfaces'

const CardCard: FC<CardProps> = ({ card, mediaUrl }) => {
  return (
    <div id={card.region} className="col-md-12">
      <div className={`panel panel-default ${card.type} ${card.codename}`}>
        {card.title ? (
          <div className="panel-heading">
            <h3 className="panel-title">{card.title}</h3>
          </div>
        ) : (
          <div></div>
        )}
        <div
          className="panel-body"
          dangerouslySetInnerHTML={{
            __html: card.content.replaceAll('{{MEDIA_IMAGE}}', mediaUrl),
          }}
        ></div>
      </div>
    </div>
  )
}

export default CardCard
