import 'moment/min/locales'

import i18n from '../../../i18nextInit'
import jsPDF from 'jspdf'
import moment from 'moment'

export const imageToDataURL = async (
  imageUrl: string,
  type: string,
  logo: boolean = false
): Promise<string> => {
  const img = await fetch(imageUrl)
  const image = await img.blob()
  const bitmap = await createImageBitmap(image)
  const canvas = document.createElement('canvas')
  const canvasContext = canvas.getContext('2d')
  canvas.width = bitmap.width
  canvas.height = bitmap.height

  if (canvasContext) {
    if (logo) {
      //gif or png need red background for ie9
      canvasContext.beginPath()
      canvasContext.moveTo(0, 0)
      canvasContext.lineTo(0, canvas.height)
      canvasContext.lineTo(canvas.width, canvas.height)
      canvasContext.lineTo(canvas.width, 0)

      const pattern = canvasContext.createPattern(bitmap, 'repeat')

      if (pattern) canvasContext.fillStyle = pattern
      canvasContext.fill()
    } else {
      //draw image into canvas element
      canvasContext.drawImage(bitmap, 0, 0, bitmap.width, bitmap.height)
    }
    return canvas.toDataURL(type)
  }
  throw new Error('no canvas context available')
}

const printTop = (
  doc: jsPDF,
  text: string,
  variables: Record<string, string | number>,
  yValue: number
) => {
  for (const [k, v] of Object.entries(variables)) {
    text = text.split(`[${k}]`).join(v as string)
  }
  doc.text(text, 20, 100)
  return (yValue += 7)
}

const printTopOld = (
  doc: jsPDF,
  variables: {
    username: string
    graduationSentence: string
    mfType: string
    grade: number
  },
  quizType: string
) => {
  if (quizType !== 'GIRO-HEROES-1') {
    let lines =
      quizType !== 'DEFAULT' && quizType !== 'DIGITAL'
        ? doc.splitTextToSize(
            `${variables.username} hat am Seminar GiroHeroes zur Finanzbildung teilgenommen.`,
            170
          )
        : doc.splitTextToSize(
            `${variables.username} hat ${variables.graduationSentence} der mecodia Akademie absolviert.`,
            170
          )
    doc.text(lines, 20, 100)
    lines =
      quizType !== 'DEFAULT' && quizType !== 'DIGITAL'
        ? doc.splitTextToSize(
            'Das Seminar vermittelt wichtiges Grundlagenwissen rund um Finanzthemen, die Schülerinnen und Schüler betreffen.',
            170
          )
        : doc.splitTextToSize(
            `Dieser Online Test stellt eine Einschätzung dar, wie kompetent ${variables.username} mit Medien umgeht.`,
            170
          )
    doc.text(lines, 20, 115)
  } else {
    let lines = doc.splitTextToSize(
      `${variables.username} hat am 1. Teil des Seminar GiroHeroes zur Finanzbildung teilgenommen.`,
      170
    )
    doc.text(lines, 20, 100)
    lines = doc.splitTextToSize(
      'Das Seminar vermittelt wichtiges Grundlagenwissen rund um Finanzthemen, die Schülerinnen und Schüler betreffen.',
      170
    )
    doc.text(lines, 20, 115)
  }
}

function printBottomOld(
  doc: jsPDF,
  variables: {
    username: string
    graduationSentence: string
    mfType: string
    grade: number
  },
  quizType: string
) {
  if (quizType !== 'GIRO-HEROES-1') {
    doc.setFont('helvetica', 'bold')
    if (quizType !== 'DEFAULT' && quizType !== 'DIGITAL') {
      doc.text(
        `${variables.username} hat das Seminar mit der Schulnote ${variables.grade} abgeschlossen.`,
        20,
        190
      )
    } else {
      doc.text(
        `${variables.username} hat den Test mit der Schulnote ${variables.grade} abgeschlossen.`,
        20,
        195
      )
    }
    doc.setFont('helvetica', 'normal')
    const lines =
      quizType !== 'DEFAULT' && quizType !== 'DIGITAL'
        ? doc.splitTextToSize(
            'Wir bedanken uns für die Teilnahme und wünschen für die Zukunft alles Gute!',
            170
          )
        : doc.splitTextToSize(
            `Wir danken herzlich für die Teilnahme am ${variables.mfType} und wünschen für die Zukunft alles Gute!`,
            170
          )
    doc.text(lines, 20, 205)
  } else {
    const lines = doc.splitTextToSize(
      'Wir freuen uns auf deine Teilnahme im 2. Teil und wünschen dir bis dahin alles Gute!',
      170
    )
    doc.text(lines, 20, 205)
  }
}

const printBottom = (
  doc: jsPDF,
  text: string,
  variables: Record<string, string | number>,
  yValue: number
) => {
  for (const [k, v] of Object.entries(variables)) {
    text = text.split(`[${k}]`).join(v as string)
  }
  doc.setFont('helvetica', 'bold')
  doc.text(text, 20, yValue)
}

export const downloadCertificate = (
  backgroundImg: string | undefined,
  logoImg: string | undefined,
  username: string,
  title: string,
  contents: string,
  quizType: string,
  certificate_text_top: string,
  certificate_text_bottom: string,
  certificate_grade: string
): void => {
  const graduationSentence =
    quizType === 'DEFAULT'
      ? 'den Medienführerschein'
      : `das digitale Angebot zum Thema TITLE HERE`
  const mfType =
    quizType === 'DEFAULT' ? 'Medienführerschein' : 'digitalen Angebot'

  //TODO: set proper locale
  const lang = i18n.language
  const date = moment().locale(lang).format('Do MMMM YYYY')
  const grade = parseInt(certificate_grade)

  if (!username) {
    alert('Bitte gebe einen Namen ein')
    return
  }
  const doc = new jsPDF()
  doc.setFont('helvetica', 'normal')
  if (backgroundImg) {
    doc.addImage(backgroundImg, 'JPEG', 0, 0, 210, 297)
  }
  if (logoImg) {
    doc.addImage(logoImg, 'JPEG', 20, 265, 72, 30)
  }
  doc.setFontSize(20)
  doc.text(username, 75, 76)
  doc.text(date, 75, 87)
  doc.setFontSize(14)

  let yValue = 100

  if (certificate_text_top) {
    yValue = printTop(
      doc,
      certificate_text_top,
      { username, graduationSentence, mfType, grade },
      yValue
    )
  } else {
    printTopOld(doc, { username, graduationSentence, mfType, grade }, quizType)
  }

  doc.setFont('helvetica', 'bold')
  doc.text(
    quizType !== 'DEFAULT' && quizType !== 'DIGITAL'
      ? doc.splitTextToSize('Inhalte des Seminars:', 200)
      : doc.splitTextToSize(`Inhalte des Tests "${title}":`, 200),
    20,
    135
  )
  const content = contents.split(',')
  yValue = 145
  for (const item of Array.from(content)) {
    doc.text('- ' + item.trim(), 20, yValue)
    yValue += 7
  }
  yValue += 7
  if (certificate_text_bottom) {
    printBottom(
      doc,
      certificate_text_bottom,
      { username, graduationSentence, mfType, grade },
      yValue
    )
  } else {
    printBottomOld(
      doc,
      { username, graduationSentence, mfType, grade },
      quizType
    )
  }
  doc.save(`Zertifikat ${title}.pdf`)
}
