import React, { FC } from 'react'
import { Form } from 'react-bootstrap'

interface CustomPasswordCheckProps {
  condition: boolean | string | undefined
  value: string
  handler: (value: string) => void
}

const CustomPasswordCheck: FC<CustomPasswordCheckProps> = ({
  condition,
  value,
  handler,
}) => {
  return (
    <Form.Control
      as="input"
      className={`${
        condition === true
          ? 'alert-success'
          : condition === 'skip'
            ? 'alert-danger'
            : ''
      }`}
      disabled={condition === true || condition === 'skip'}
      value={value}
      style={condition === true ? { color: '#5CB85C' } : { color: '' }}
      onChange={(event) => {
        handler(event.target.value)
      }}
    ></Form.Control>
  )
}

export default CustomPasswordCheck
