import React, { FC } from 'react'
import { Form } from 'react-bootstrap'

interface CustomTextAnswerProps {
  condition: boolean | string | undefined
  value: string
  handler: (value: string) => void
}

const CustomTextAnswer: FC<CustomTextAnswerProps> = ({
  condition,
  value,
  handler,
}) => {
  return (
    <Form.Control
      id="input"
      as="textarea"
      disabled={condition === true}
      className="form-control"
      onChange={(event) => {
        handler(event.target.value)
      }}
      value={value}
    ></Form.Control>
  )
}

export default CustomTextAnswer
